<template>
    <div class="page page--login">
        <div class="section-parallax" @mousemove="onParallax">
            <img src="/img/icons-1.png" class="section-parallax__img" :style="styleParallax">
            <img src="/img/icons-2.png" class="section-parallax__img" :style="styleParallax">
        </div>
        <div class="section-content">

            <ButtonBack :name="'ВЕРНУТЬСЯ НАЗАД'" v-if="$route.name === 'main'" />

            <div class="card card--confirm">
                <router-view v-slot="{ Component }">
                    <component :is="Component" />
                </router-view>
            </div>

        </div>
        <div class="container footer-login">
            <div class="footer-login__wrap">
                <a href="/" class="logo">
                    <img src="/img/logo.svg">
                </a>
                <div class="footer-login__text">Принимаем к оплате:</div>
                <img class="pay-info" src="/img/pay-keeper.png"/>
            </div>
            <div class="footer-login__grid">
                <a href="/basic-information" class="footer-login__link">Основные сведения</a>
                <a href="/information" class="footer-login__link">
                    Информация об оплате, возврате оплаты, доставке и подачи заявления на обучение
                </a>
                <a href="/personal-data-policy/personal-data-policy" class="footer-login__link">
                    Политика обработки персональных данных
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    setup() {
        const styleParallax = reactive({})

        const onParallax = event => {
            const x = event.clientX / window.innerWidth
            const y = event.clientY / window.innerHeight

            styleParallax.transform = 'translate(-' + x * 50 + 'px, -' + y * 50 + 'px)'
        }

        return { onParallax, styleParallax }
    },
}
</script>
